import {Component, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {Subscription} from 'rxjs';

import {AppState} from '@core/states/app/app.state';
import {LangType} from '@core/types/lang.type';
import {LangModel, LANGS} from '@core/models/local/lang.model';
import {UpdateLang} from '@core/states/app/actions';

@Component({
  selector: 'core-header-lang-toggle',
  templateUrl: './header-lang-toggle.component.html',
  styleUrls: ['./header-lang-toggle.component.scss'],
})
export class HeaderLangToggleComponent implements OnDestroy {

  langs: any[];

  currLang: LangModel;

  langSubscription: Subscription;

  collapsed = true;

  constructor(
    private store: Store
  ) {
    this.langSubscription = this.store.select(AppState.lang)
      .subscribe(lang => {
        this.currLang = LANGS.find(l => l.id === lang);
        this.langs = [this.currLang, ...LANGS.filter(l => l.id !== lang)];
      });
  }

  ngOnDestroy(): void {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }
  }

  onOpenPicker(): void {
    this.collapsed = !this.collapsed;
  }

  onUpdateLang(lang: LangType): void {
    this.store.dispatch(new UpdateLang(lang));
  }
}
