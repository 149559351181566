import {ModuleWithProviders, NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {RouterModule} from '@angular/router'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {NgxsModule} from '@ngxs/store'
import {NgxsStoragePluginModule} from '@ngxs/storage-plugin'
import {NgxsRouterPluginModule} from '@ngxs/router-plugin'
import {TranslateModule} from '@ngx-translate/core'
import {ModalModule} from 'ngx-bootstrap/modal'
import {BsDropdownModule} from 'ngx-bootstrap/dropdown'

import {AlertsComponent} from '@core/components/alerts/alerts.component'
import {HeaderComponent} from '@core/components/header/header.component'
import {HeaderLangToggleComponent} from '@core/components/header/lang-toggle/header-lang-toggle.component'
import {HeaderGroupToggleComponent} from '@core/components/header/group-toggle/header-group-toggle.component'
import {NavigatorComponent} from '@core/components/navigator/navigator.component'
import {LoaderComponent} from '@core/components/loader/loader.component'
import {DialogComponent} from '@core/components/dialog/dialog.component'
import {BreadcrumbsComponent} from '@core/components/breadcrumb/breadcrumbs.component'

import {LoaderInterceptor} from '@core/interceptors/loader.intercepter'
import {LoaderState} from '@core/states/loader/loader.state'
import {AppState} from '@core/states/app/app.state'
import {AuthState} from '@core/states/auth/auth.state'
import {RequestInterceptor} from '@core/interceptors/request.interceptor'
import {MathjaxHtmlModule} from '@shared/components/mathjax-html/mathjax-html.module'
import {AuthGuard} from '@core/guards/auth.guard'
import {RedirectGuard} from '@core/guards/redirect.guard'
import {IconModule} from '@shared/components/icon/icon.module'
import {FilterState} from '@core/states/filter/filter.state'
import {BreadcrumbsState} from '@core/states/breadcrumbs/breadcrumbs.state'
import {EntitiesPipeModule} from '@shared/pipes/entities/entities-pipe.module'
import {TabState} from './states/tab/tab.state'
import {PaginationState} from './states/pagination/pagination.state'

@NgModule({
  declarations: [
    AlertsComponent,
    HeaderComponent,
    HeaderLangToggleComponent,
    HeaderGroupToggleComponent,
    NavigatorComponent,
    LoaderComponent,
    DialogComponent,
    BreadcrumbsComponent,
  ],
  exports: [
    AlertsComponent,
    HeaderComponent,
    NavigatorComponent,
    LoaderComponent,
    DialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot(),
    ModalModule.forRoot(),
    NgxsModule.forRoot([AppState, AuthState, LoaderState, FilterState, TabState, PaginationState, BreadcrumbsState]),
    NgxsStoragePluginModule.forRoot({keys: ['app', 'auth', 'filter', 'tabsetState']}),
    NgxsRouterPluginModule.forRoot(),
    MathjaxHtmlModule.forRoot(),
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    IconModule,
    EntitiesPipeModule,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
        AuthGuard,
        RedirectGuard,
      ],
    }
  }
}
