import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren, ChangeDetectorRef, AfterContentChecked, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { AuthState } from '@core/states/auth/auth.state';
import { GROUP_LABEL_MAP, GroupType } from '@core/types/group.type';
import { UpdateGroup } from '@core/states/auth/actions';

@Component({
  selector: 'core-header-group-toggle',
  templateUrl: './header-group-toggle.component.html',
  styleUrls: ['./header-group-toggle.component.scss'],
})
export class HeaderGroupToggleComponent implements AfterViewInit, OnDestroy, AfterContentChecked {

  groups: GroupType[];

  currentGroup: GroupType;

  currentGroupSubscription: Subscription;

  groupLabelMap = GROUP_LABEL_MAP;

  collapsed = true;

  collapsedWidth: number;

  @ViewChildren('group') groupDOMElements: QueryList<any>;
  @ViewChildren('groupitem') groupitem: QueryList<any>;

  constructor(
    private store: Store,
    private changeDetector: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    this.currentGroupSubscription = this.store.select(AuthState.currentGroup)
      .subscribe(currentGroup => {
        const systemGroups = this.store.selectSnapshot(AuthState.systemGroups);
        if (systemGroups.length > 1) {
          this.currentGroup = currentGroup;
          this.groups = [currentGroup, ...systemGroups.filter(group => group !== currentGroup)];
        }
      });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges()
  }

  ngAfterViewInit() {
    this.collapsedWidth = this.groupDOMElements.first?.nativeElement.offsetWidth;
    this.changeDetector.detectChanges()
    this.collapseController()
  }

  collapseController() {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (!(!!this.groupDOMElements.find(item => item?.nativeElement === e.target) || !!this.groupitem.find(item => item?.nativeElement === e.target))) {
        this.collapsed = true
      }
    });
  }

  ngOnDestroy(): void {
    if (this.currentGroupSubscription) {
      this.currentGroupSubscription.unsubscribe();
    }
  }

  getGroupName(group: GroupType): string {
    return GROUP_LABEL_MAP[group];
  }

  onOpenPicker(): void {
    this.collapsed = !this.collapsed;
  }

  onUpdateGroup(group: GroupType, index: number): void {
    this.store.dispatch(new UpdateGroup(group));
    this.collapsedWidth = this.groupDOMElements.toArray()[index].nativeElement.offsetWidth;
  }
}
