<div class="wrapper" *ngIf="currentGroup">

  <div
    class="groups"
    [class.collapsed]="collapsed"
    [style.max-width.px]="collapsed ? collapsedWidth + 60 : 318"
    (click)="onOpenPicker()"
  >

    <div
      #groupitem
      class="group"
      *ngFor="let group of groups; let i = index"
      (click)="onUpdateGroup(group, i)"
    >

      <img class="logo" src="./assets/img/system_avatar.svg">

      <span #group class="name">{{groupLabelMap[group]}}</span>

      <img
        class="arrow-down"
        src="./assets/img/arrow-down.svg"
        [class.hidden]="group !== currentGroup || !collapsed"
      >

    </div>

  </div>

</div>
