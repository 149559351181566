import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import moment from 'moment';

import { IdentityService } from '@core/services/api/identity.service';
import { AuthState } from '@core/states/auth/auth.state';
import { extractHttpErrorMessage } from '@core/utils/url.utils';
import { DialogService } from '@core/services/local/dialog.service';
import { RemoveToken, UpdateToken } from '@core/states/auth/actions';
import { AppState } from '@core/states/app/app.state';
import { CustomHttpParamEncoder } from '@core/utils/custom-codec';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  refreshTokenInProgress = false;

  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private store: Store,
    private router: Router,
    private dialogService: DialogService,
    private identityService: IdentityService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = this.addAuthHeader(request);

    request = this.changeParamsEncoding(request)

    if (this.isTokenExpired(request)) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          }

          this.store.dispatch([RemoveToken, new Navigate(['/auth'])]);
          return throwError(e);
        }));
    }

    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next);
      })
    );
  }

  changeParamsEncoding(request: HttpRequest<any>){
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromString: request.params.toString(),
    });

    return request.clone({
      params
    })
  }

  addAuthHeader(request: HttpRequest<any>) {
    const isJooReq = request.url.includes('joo.kz')
    const token = this.store.selectSnapshot(AuthState.token);

    if (isJooReq) {
      request = request.clone({
        setHeaders: {
          'Accept-Language': this.store.selectSnapshot(AppState.lang)
        }
      });
    }

    if (isJooReq && token) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token.access}`
        }
      });
    }

    return request;
  }

  isTokenExpired(request: HttpRequest<any>): boolean {
    if (request.params.has('skip_error_handling')) {
      return false;
    }

    // @ts-ignore
    const { token, accessTokenExpireDate } = this.store.selectSnapshot(AuthState);
    if (!token || !accessTokenExpireDate) {
      return false;
    }

    const leftSeconds = moment().diff(accessTokenExpireDate, 'seconds');

    return leftSeconds > -30;
  }

  handleResponseError(error, request?, next?) {
    if (request.params.has('skip_error_handling')) {
      return throwError(error);
    }

    if (error.status === 401) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader(request);
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError(e);
          }

          this.store.dispatch([RemoveToken, new Navigate(['/auth'])]);
          return throwError(e);
        }));
    }

    let message = '';

    if (error.status === 0) {
      message = request
        ? `Доступ к <code>${request.url}</code> заблокирован политикой CORS. Подробности смотрите в консоли браузера.`
        : `Доступ к HTTP-адресу заблокирован политикой CORS. Подробности смотрите в консоли браузера.`;
    } else if (error.status >= 500) {
      message = 'errors.server_error';
    } else if (error.status === 404) {
      message = 'errors.not_found';
    } else {
      message = extractHttpErrorMessage(error.error);
    }

    this.dialogService.error({ message });

    return throwError(error);
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      const token = this.store.selectSnapshot(AuthState.token);

      return this.identityService.refreshToken(token.refresh).pipe(
        map(token => {
          this.store.dispatch(new UpdateToken(token));
          return token;
        }),
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError(error => {
          this.refreshTokenInProgress = false;
          return throwError(error);
        }));
    }
  }
}
