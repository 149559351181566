import { Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Logout, NavigateHome } from '@core/states/auth/actions';
import { LangModel, LANGS } from '@core/models/local/lang.model';
import { AuthState } from '@core/states/auth/auth.state';
import { GroupType } from '@core/types/group.type';
import { Router } from '@angular/router';

@Component({
  selector: 'core-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Select(AuthState.currentGroup) currentGroup$: Observable<GroupType>;
  @Select(AuthState.isSupport) isSupport$: Observable<boolean>;
  @Select(AuthState.isCourseReporter) isCourseReporter$: Observable<boolean>;
  langs: LangModel[] = LANGS;

  constructor(
    private store: Store,
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  onNavigateHome(): void {
    this.store.dispatch(NavigateHome);
  }

  onLogout(): void {
    this.store.dispatch(Logout);
  }
}
