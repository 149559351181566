import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@core/guards/auth.guard';
import { RedirectGuard } from '@core/guards/redirect.guard';
import { NavigatorComponent } from '@core/components/navigator/navigator.component';

const routes: Routes = [
  {
    path: '',
    component: NavigatorComponent,
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
    canActivate: [RedirectGuard],
  },
  {
    path: 'testolog',
    loadChildren: () => import('./features/testolog/testolog.module').then(m => m.TestologModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'expert',
    loadChildren: () => import('./features/expert/expert.module').then(m => m.ExpertModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'moderator',
    loadChildren: () => import('./features/moderator/moderator.module').then(m => m.ModeratorModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'questions-moderator',
    loadChildren: () => import('./features/moderator/moderator.module').then(m => m.ModeratorModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'module-comment-manager',
    loadChildren: () => import('./features/module-comment-manager/module-comment-manager.module').then(m => m.ModuleCommentManagerModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'custom-tests-moderator',
    loadChildren: () => import('./features/custom-tests-moderator/custom-tests-moderator.module').then(m => m.CustomTestsModeratorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supervisor-manager',
    loadChildren: () => import('./features/supervisor-manager/supervisor-manager.module').then(m => m.SupervisorManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sales-manager',
    loadChildren: () => import('./features/sales-manager/sales-manager.module').then(m => m.SalesManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stream-manager',
    loadChildren: () => import('./features/stream-manager/stream-manager.module').then(m => m.StreamManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'subject-manager',
    loadChildren: () => import('./features/subject-manager/subject-manager.module').then(m => m.SubjectManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trainer-manager',
    loadChildren: () => import('./features/trainer-manager/trainer-manager.module').then(m => m.TrainerManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'teacher',
    loadChildren: () => import('./features/teacher/teacher.module').then(m => m.TeacherModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'supervisor',
    loadChildren: () => import('./features/supervisor/supervisor.module').then(m => m.SupervisorModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'polls-manager',
    loadChildren: () => import('./features/polls-manager/polls-manager.module').then(m => m.PollManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trial-test-manager',
    loadChildren: () => import('./features/trial-test-manager/trial-test-manager.module').then(m => m.TrialTestManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trial-test-variant-manager',
    loadChildren: () => import('./features/trial-test-variant-manager/trial-test-variant-manager.module').then(m => m.TrialTestVariantManagerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'trial-test-checker',
    loadChildren: () => import('./features/trial-test-checker/trial-test-checker.module').then(m => m.TrialTestCheckerModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
