import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';

import {UpdateBreadcrumbs} from './actions';

import {BreadcrumbModel} from '@core/models/local/breadcrumb.model';


interface BreadcrumbsStateModel {
  items: BreadcrumbModel[];
}

const defaults: BreadcrumbsStateModel = {
  items: [],
};

@State<BreadcrumbsStateModel>({
  name: 'breadcrumbs',
  defaults
})
@Injectable()
export class BreadcrumbsState {

  @Selector()
  static items(state: BreadcrumbsStateModel): BreadcrumbModel[] {
    return state.items;
  }

  @Action(UpdateBreadcrumbs)
  UpdateBreadcrumbs({patchState, getState}: StateContext<BreadcrumbsStateModel>, {items}: UpdateBreadcrumbs) {
    patchState({items});
  }
}
