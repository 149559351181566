<div class="wrapper" *ngIf="breadcrumbs$ | async as breadcrumbs">

  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">

    <a class="nav-item" [ngSwitch]="breadcrumb.type" [class.last]="last" (click)="onClickBreadcrumb($event, breadcrumb)">

      <ng-container *ngSwitchCase="'pipe'">

        <ng-container [ngSwitch]="breadcrumb.pipe">
          <ng-container *ngSwitchCase="'entities'">{{breadcrumb.title | entities | async}}</ng-container>
        </ng-container>

      </ng-container>

      <ng-container *ngSwitchDefault>{{breadcrumb.title}}</ng-container>

    </a>

    <div *ngIf="!last" class="separator">/</div>

  </ng-container>

</div>
