import {Component} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {LoaderState} from '@core/states/loader/loader.state';

@Component({
  selector: 'core-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {

  @Select(LoaderState.showLoader) showLoader$: Observable<boolean>;
}
