<div class="wrapper">

  <div class="fixed-wrapper">

    <nav>

      <div class="d-flex align-items-center">

        <img class="logo" src="./assets/img/logo.svg" (click)="onNavigateHome()"/>

        <div *ngIf="currentGroup$ | async as currentGroup" class="nav-items ms-4">

          <ng-container [ngSwitch]="currentGroup">

            <ng-container *ngSwitchCase="'testolog'">

              <a class="nav-item" routerLink="/testolog/questions-original">{{'Оригинальные вопросы' | translate}}</a>

              <a class="nav-item" routerLink="/testolog/headings-original">{{'Групповые вопросы' | translate}}</a>

              <a class="nav-item" routerLink="/testolog/question-orders">{{'Фасетные вопросы' | translate}}</a>

            </ng-container>

            <ng-container *ngSwitchCase="'expert'">

              <a class="nav-item" routerLink="/expert/questions-original">{{'Оригинальные вопросы' | translate}}</a>

              <a class="nav-item" routerLink="/expert/headings-original">{{'Групповые вопросы' | translate}}</a>

              <a class="nav-item" routerLink="/expert/questions-facet">{{'Фасетные вопросы' | translate}}</a>

            </ng-container>

            <ng-container *ngSwitchCase="'moderator'">

              <div class="nav-item nav-item-dropdown" dropdown>

                <div class="nav-item-dropdown-wrapper" dropdownToggle (click)="false">
                  <span>{{'Вопросы' | translate}}</span>
                  <shared-icon name="sort-down"></shared-icon>
                </div>

                <ul *dropdownMenu class="dropdown-menu">
                  <li>
                    <a href="#" class="dropdown-item"
                       routerLink="/moderator/questions">{{'Все вопросы' | translate}}</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item"
                       routerLink="/moderator/headings">{{'Все заголовки' | translate}}</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item"
                       routerLink="/moderator/question-orders">{{'Все заказы' | translate}}</a>
                  </li>
                </ul>

              </div>

              <div class="nav-item nav-item-dropdown" dropdown>

                <div class="nav-item-dropdown-wrapper" dropdownToggle (click)="false">
                  <span>{{'Контент' | translate}}</span>
                  <shared-icon name="sort-down"></shared-icon>
                </div>

                <ul *dropdownMenu class="dropdown-menu">
                  <li>
                    <a href="#" class="dropdown-item" routerLink="/moderator/subjects">{{'Предметы' | translate}}</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item" routerLink="/moderator/themes">{{'Темы' | translate}}</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item" routerLink="/moderator/subthemes">{{'Подтемы' | translate}}</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item" routerLink="/moderator/modules">{{'Модули' | translate}}</a>
                  </li>
                </ul>

              </div>

              <a class="nav-item" routerLink="/moderator/profiles">{{'Пользователи' | translate}}</a>

<!--              <a class="nav-item" routerLink="/moderator/personal-plan">{{'Индивидуальные планы' | translate}}</a>-->

              <a class="nav-item" *ngIf="isSupport$ | async" routerLink="/moderator/requests">Поддержка</a>

              <a class="nav-item" routerLink="/moderator/reports">{{'Отчеты' | translate}}</a>

            </ng-container>

            <ng-container *ngSwitchCase="'questions-moderator'">

              <div class="nav-item nav-item-dropdown" dropdown>

                <div class="nav-item-dropdown-wrapper" dropdownToggle (click)="false">
                  <span>{{'Вопросы' | translate}}</span>
                  <shared-icon name="sort-down"></shared-icon>
                </div>

                <ul *dropdownMenu class="dropdown-menu">
                  <li>
                    <a href="#" class="dropdown-item"
                       routerLink="/questions-moderator/questions">{{'Все вопросы' | translate}}</a>
                  </li>
                  <li>
                    <a href="#" class="dropdown-item"
                       routerLink="/questions-moderator/headings">{{'Все заголовки' | translate}}</a>
                  </li>
                </ul>

              </div>

            </ng-container>


<!--            <ng-container *ngSwitchCase="'module-comment-manager'">-->

<!--              <a class="nav-item" routerLink="/module-comment-manager/modules-comments">{{'Комментарий модулей' | translate}}</a>-->

<!--            </ng-container>-->

            <ng-container *ngSwitchCase="'supervisor-manager'">

              <a class="nav-item" routerLink="/supervisor-manager/supervisors">Кураторы</a>

              <a class="nav-item" routerLink="/supervisor-manager/groups">Группы</a>

            </ng-container>

            <ng-container *ngSwitchCase="'stream-manager'">

              <a class="nav-item" [class.selected]="router.url.includes('/stream-manager/list')" routerLink="/stream-manager/list">Потоки</a>
              <a class="nav-item" [class.selected]="router.url.includes('/stream-manager/reports')" routerLink="/stream-manager/reports">Отчеты</a>

            </ng-container>

            <ng-container *ngSwitchCase="'supervisor'">

              <a class="nav-item" [class.selected]="router.url.includes('/supervisor/broadcasts')" routerLink="/supervisor/broadcasts">Уроки в эфире</a>

              <a class="nav-item" [class.selected]="router.url.includes('/supervisor/students')" routerLink="/supervisor/students">Список учеников</a>

              <a class="nav-item" [class.selected]="router.url.includes('/supervisor/streams')" routerLink="/supervisor/streams">Список потоков</a>

            </ng-container>

            <ng-container *ngSwitchCase="'courses-moderator'">

              <a class="nav-item" routerLink="/courses-moderator/student-courses">Курсы</a>

              <a class="nav-item" *ngIf="isCourseReporter$ | async" routerLink="/courses-moderator/student-reports">Отчет</a>

            </ng-container>

            <ng-container *ngSwitchCase="'offline-courses-moderator'">

              <a class="nav-item" routerLink="/offline-courses-moderator/master-plans">Уроки</a>

              <a class="nav-item" routerLink="/offline-courses-moderator/groups">Группы</a>

              <a class="nav-item" routerLink="/offline-courses-moderator/trial-tests">Пробные тесты</a>

            </ng-container>

            <ng-container *ngSwitchCase="'offline-teacher'">

              <a class="nav-item" routerLink="/offline-teacher/groups">Группы</a>

            </ng-container>

            <ng-container *ngSwitchCase="'custom-tests-moderator'">

              <a class="nav-item" routerLink="/custom-tests-moderator/tests">Тесты</a>

              <a class="nav-item" routerLink="/custom-tests-moderator/questions">Вопросы</a>

              <a class="nav-item" routerLink="/custom-tests-moderator/headings">Заголовки</a>

            </ng-container>

            <ng-container *ngSwitchCase="'trial-test-checker'">

              <a class="nav-item" routerLink="/trial-test-checker/review">Проверка теста</a>

              <a class="nav-item" routerLink="/trial-test-checker/tests">Все тесты</a>

            </ng-container>

          </ng-container>

        </div>

      </div>

      <div class="d-none" [innerMathjaxHTML]="'<div></div>'"></div>

      <div class="nav-items">

        <div class="nav-item" *ngIf="currentGroup$ | async">
          <core-header-group-toggle></core-header-group-toggle>
        </div>

        <div class="nav-item">
          <core-header-lang-toggle></core-header-lang-toggle>
        </div>

        <div class="nav-item" *ngIf="currentGroup$ | async" translate="auth.sign_out" (click)="onLogout()"></div>

      </div>

    </nav>

    <core-breadcrumbs></core-breadcrumbs>

  </div>

</div>
