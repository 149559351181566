import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {Observer} from 'rxjs'

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from '@core/core.module';

declare global {
  interface Window {
    hubReady: Observer<boolean>;
    MathJax: {
      Hub: any;
    };
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule.forRoot(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
